import React, { useContext } from 'react';
import styles from './style.module.scss';
import CheckmarkIcon from 'components/icon/checkmark-icon';
import Button from 'components/control/button';
import { IModalContext, ModalContext } from 'contexts/modal';
import { translate } from 'utils/translations';
import { useUser } from 'contexts';

interface Props {
  isFromConnectAgent?: boolean;
}

export default function SignUpCta({ isFromConnectAgent }: Props) {
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { siteLocation } = useUser();

  const redirectToLogin = () => {
    openModal('login-registration');
  };

  return (
    <div className={styles['component']}>
      <p>
        {isFromConnectAgent ?
          'Create a free account'
          :
          'Sign up to save this listing'
        }
      </p>
      <p className={styles['benefit-item']}>
        <CheckmarkIcon />Save your {translate('favourite', siteLocation)} listings
      </p>
      <p className={styles['benefit-item']}>
        <CheckmarkIcon />Get alert for similar listings
      </p>
      <p className={styles['benefit-item']}>
        <CheckmarkIcon />View listing details
      </p>
      <Button label="Log In or Sign Up" theme="primary" onClick={redirectToLogin} />
    </div>
  );
}
