import { HttpRequestMethodTypes, JSON_CONTENT_TYPE_HEADER } from 'types';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { FetchOptions } from 'types/fetchWithRetry';

import type { ConsentRequestBodyType } from './types';

export default async function captureFubUserWithConsent(body: ConsentRequestBodyType) {
  const options: FetchOptions = { 
    headers: JSON_CONTENT_TYPE_HEADER, 
    method: HttpRequestMethodTypes.POST,
    body: JSON.stringify(body),
  };

  try {
    const response = await fetchWithRetry('/api/consent-disclaimers/handler', options);
    const parsedResponse = await response.json();
    if (response.ok) {
      return parsedResponse;
    } else if ('error' in parsedResponse) {
      const { error } = parsedResponse;
      return new Error(error?.message) || new Error(`Request failed with status ${response.status}`);
    }

  } catch (error) {
    const wrappedError = new Error(`There was an error sending the user consent: ${(error as Error)?.message}`, { cause: error as Error });
    console.error(wrappedError);
    return wrappedError;
  }
}
