import { IUserContext, UserContext, useThemeContext } from 'contexts';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CountryCodeList } from 'types/countries';
import { ThemeNames } from 'types/themes';

const useConsentLabels = (buttonText: string) => {
  const { themeName } = useThemeContext();
  const { userLocation } = useContext(UserContext) as IUserContext;

  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  const isUserLocationInCanada = userLocation?.countryCode == CountryCodeList.CANADA;

  const PRIVACY_LABEL_DEFAULT = useMemo(() => <p>By checking this box, and clicking “{buttonText}”, I am confirming that I have read and agree to <a href="https://www.zoocasa.com/privacy-policy" target="_blank">Zoocasa&apos;s Data Processing & Privacy Policy</a>, and to <a href="https://www.zoocasa.com/terms-of-use" target="_blank">Zoocasa&apos;s Terms of Use</a>.</p>, [buttonText]);
  
  const PRIVACY_LABEL_EXP_OUTSIDE_CANADA = useMemo(() => <p>By pressing “{buttonText}”, I confirm that I have read and agree to the <a href="https://expworldholdings.com/privacy-policy/" target="_blank">eXp Privacy Policy</a>, and <a href="https://expworldholdings.com/terms-of-service/" target="_blank">Terms of Use</a>.</p>, [buttonText]);

  const MARKETING_LABEL_DEFAULT = useMemo(() => `By checking this box, and clicking “${buttonText}”, I am electronically signing and consenting to receive marketing communications (about real estate brokerage services and one or more properties that I may buy, sell, or rent), that may be delivered to me via email, telephone and/or text messaging (using “automated technology” such as an automatic telephone dialing system, and/or an artificial or prerecorded voice), from Zoocasa, eXp Realty® and its independent contractor real estate agents, and/or its or their affiliates, using the contact information I provided.  Consent is not a condition of purchase.  I may opt-out at any time by emailing info@zoocasa.com or calling 1-844-683-4664.`, [buttonText]);

  const MARKETING_LABEL_EXP_OUTSIDE_CANADA = useMemo(() => `By pressing “${buttonText}”, I am electronically signing and consenting to receive marketing communications (about real estate brokerage services and one or more properties that I may buy, sell, or rent), that may be delivered to me via email, telephone and/or text messaging (using “automated technology” such as an automatic telephone dialing system, and/or an artificial or prerecorded voice), from eXp Realty® and its independent contractor real estate professionals using the contact information I provided. Your consent is not a condition of purchase and you may revoke it at any time by replying “STOP” to any of our texts or by emailing privacy@expworldholdings.com. Message and data rates may apply. You may also contact us directly at 1-844-683-4664.`, [buttonText]);

  const MARKETING_LABEL_ZOOCASA_OUTSIDE_CANADA = useMemo(() => `By pressing “${buttonText}”, I am electronically signing and consenting to receive marketing communications (about real estate brokerage services and one or more properties that I may buy, sell, or rent), that may be delivered to me via email, telephone and/or text messaging (using “automated technology” such as an automatic telephone dialing system, and/or an artificial or prerecorded voice), from eXp Realty® and its independent contractor real estate professionals using the contact information I provided. Your consent is not a condition of purchase and you may revoke it at any time by replying “STOP” to any of our texts or by emailing privacy@expworldholdings.com. Message and data rates may apply. You may also contact us directly at 1-844-683-4664.`, [buttonText]);

  const [privacyPolicyConsentLabel, setPrivacyPolicyConsentLabel] = useState<JSX.Element>(PRIVACY_LABEL_DEFAULT);
  const [marketingCommunicationsConsentLabel, setMarketingCommunicationsConsentLabel] = useState(MARKETING_LABEL_DEFAULT);

  useEffect(() => {
    if (isUserLocationInCanada) {
      // Right now the app is configured to NOT display any consent text for users within Canada, so we'll default to Zoocasa consent text
      setPrivacyPolicyConsentLabel(PRIVACY_LABEL_DEFAULT);
      setMarketingCommunicationsConsentLabel(MARKETING_LABEL_DEFAULT);
    } else {
      if (isExpTheme) {
        // Show eXp specific consent text to eXp users outside Canada
        setPrivacyPolicyConsentLabel(PRIVACY_LABEL_EXP_OUTSIDE_CANADA);
        setMarketingCommunicationsConsentLabel(MARKETING_LABEL_EXP_OUTSIDE_CANADA);
      } else {
        // Show Zoocasa specific consent text to non-Canadian users
        setPrivacyPolicyConsentLabel(PRIVACY_LABEL_DEFAULT);
        setMarketingCommunicationsConsentLabel(MARKETING_LABEL_ZOOCASA_OUTSIDE_CANADA);
      }
    }
  }, [isExpTheme, isUserLocationInCanada, buttonText, PRIVACY_LABEL_DEFAULT, PRIVACY_LABEL_EXP_OUTSIDE_CANADA, MARKETING_LABEL_DEFAULT, MARKETING_LABEL_EXP_OUTSIDE_CANADA, MARKETING_LABEL_ZOOCASA_OUTSIDE_CANADA]);

  return { privacyPolicyConsentLabel, marketingCommunicationsConsentLabel };

};

export { useConsentLabels };