import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbarContext } from 'contexts';
import browser from '@zoocasa/node-kit/browser';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';

export default function SnackbarComponent() {
  const { snackbarData, closeSnackbar } = useSnackbarContext();
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    setIsSafari(browser.isSafari());
  }, []);

  return (
    <Snackbar
      open={snackbarData?.visible}
      autoHideDuration={10000}
      onClose={closeSnackbar}
      message={
        <div className={styles.container}>
          <div onClick={closeSnackbar}><CloseIcon className={styles.close} /></div>
          {snackbarData?.message}
        </div>
      }
      className={buildClassName(styles.snackbar, snackbarData?.isError && styles.error, isSafari && styles.safari)}
    />
  );
}
