import type { FieldValues, UseFormSetError } from 'react-hook-form';

export type DisclaimerCheck = {
    isUserOutsideCanada: boolean;
    termsPrivacy: boolean;
    hasConsent: boolean;
    setError: UseFormSetError<FieldValues>;
    handleConnRequestDisclaimers: (isChecked: { isChecked: boolean }) => void;
};

export type SignupDisclaimerCheck = {
  isUserOutsideCanada: boolean;
  termsPrivacy: boolean;
  setError: UseFormSetError<FieldValues>;
};

export function areDisclaimersChecked({ isUserOutsideCanada, termsPrivacy, hasConsent, setError, handleConnRequestDisclaimers }: DisclaimerCheck) {
  if (isUserOutsideCanada && termsPrivacy && hasConsent) {
    handleConnRequestDisclaimers({ isChecked: true });
    return true;
  } else if (isUserOutsideCanada) {
    !termsPrivacy && setError('termsPrivacy', { message: 'Not checked' });
    !hasConsent && setError('hasConsent', { message: 'Not checked' });
    handleConnRequestDisclaimers({ isChecked: false });
    return false;
  }
  return true;
}

export function isSignupDisclaimerChecked({ isUserOutsideCanada, termsPrivacy, setError }: SignupDisclaimerCheck) {
  if (isUserOutsideCanada && termsPrivacy) {
    return true;
  } else if (isUserOutsideCanada) {
    !termsPrivacy && setError('termsPrivacy', { message: 'Not checked' });
    return false;
  }
  return true;
}
